var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "Go2AuthElama"
  }, [!_vm.message ? _c('Spinner', {
    staticClass: "promocode__spinner",
    attrs: {
      "dark": true
    }
  }) : _c('div', {
    staticClass: "Go2AuthElama__message"
  }, [_vm._v(" " + _vm._s(_vm.message) + " ")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };