import "core-js/modules/es.array.push.js";
import Spinner from "@/components/Loading/Indicator.vue";
export default {
  components: {
    Spinner
  },
  data() {
    return {
      getParams: this.$route.query,
      message: null,
      timer: null
    };
  },
  async created() {
    const {
      url,
      message
    } = await this.$store.dispatch("user/link2goelama", this.getParams);
    if (url) {
      window.location = url;
    }
    if (message) {
      this.message = message;
      this.timer = setTimeout(() => {
        this.$router.push({
          name: "Login"
        });
      }, 5000);
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
};